// page template

import React from "react"

import Layout from "../layouts/layout"
import SEO from "../components/seo"

import styled from "styled-components"
import { Container, Col, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import blockSerializer from "../service/blockSerializer"

// page container component
const PageContainer = styled(Container)`
  background-color: rgba(238, 238, 238, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 50px;
  margin: 80px auto;
  z-index: 1;
`

const PageTitle = styled(Container)`
  color: ${props => props.theme.navLinkColor};
  overflow: visible;
  h1 {
    @media (max-width: ${props => props.theme.mobile}) {
      padding: 0;
      font-size: 4rem;
    }
  }
  margin: 0 auto;
`

// request for page data from Sanity to build the page;
// the id was passed as context from gatsby-node.js
export const data = graphql`
  query PageTemplateQuery($id: String!) {
    site: sanityPage(id: { eq: $id }) {
      id
      title
      type
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
  }
`

// page template
const page = ({ data, errors }) => {
  return (
    <Layout showHeader={true} showLogo={true}>
      <SEO title={data?.site?.title} />
      <Row>
        <Col>
          <PageTitle>
            <h1>{data?.site?.title}</h1>
          </PageTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <PageContainer>
            <BlockContent
              blocks={data?.site?._rawBody}
              serializers={blockSerializer}
            />
          </PageContainer>
        </Col>
      </Row>
    </Layout>
  )
}

export default page
